import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import BlogWrapper from "../../../components/article/BlogWrapper";
import { Link } from "gatsby";

const SEO = {
  MetaTitle: "AI Scams - What You Should Know",
  MetaDescription:
    "Learn about AI Scams and how to recognize them. WaFd Bank explains different types of AI Scams as well as how to protect yourself from these scams.",
  Schema: {
    "@id": "https://www.wafdbank.com/blog/security-privacy/ai-scams-what-you-should-know",
    "@type": "Article",
    image: "https://www.wafdbank.com/images/adsearch/blogs/og-what-you-need-to-know-about-ai-scams-100124.jpg",
    "@context": "https://schema.org",
    headline: "AI Scams - What You Should Know",
    description:
      "Learn about AI Scams and how to recognize them. WaFd Bank explains different types of AI Scams as well as how to protect yourself from these scams."
  }
};

const AIScamsWhatYouShouldKnow = () => {
  const cards = [
    "/blog/security-privacy/how-to-identify-prevent-scams-before-they-happen",
    "/blog/security-privacy/protecting-elderly-financial-abuse",
    "/blog/security-privacy/protect-your-bank-accounts"
  ];

  return (
    <BlogWrapper
      title={"What You Need to Know About AI Scams"}
      pageUrl={"/blog/security-privacy/ai-scams-what-you-should-know"}
      eightColumnsTemplate
      ogImage={"https://www.wafdbank.com/images/adsearch/blogs/og-what-you-need-to-know-about-ai-scams-100124.jpg"}
      seoData={SEO}
      cards={cards}
      contactInfo={"LocalBranch"}
    >
      <>
        <h4>
          Artificial intelligence has changed the world and the way fraudsters try to scam people out of their
          hard-earned money. Understanding how AI has changed how scams work is critical to protecting yourself, your
          information, and your money. Here's what you need to know.
        </h4>
        <StaticImage
          src="../../../images/blogs/security-privacy/ai-scams-what-you-should-know/thumbnail-what-you-need-to-know-about-ai-scams-100124.jpg"
          alt={`Man looking frustrated while looking at his mobile phone.`}
          placeholder="blurred"
          quality="100"
        />
        <h2>Scams Are Always Evolving</h2>
        <p>
          As life changes, so do scams. With so much happening digitally, new, more sophisticated scams that are harder
          to spot are occurring more and more. With AI, fraudsters can automate and improve their tactics to make their
          scams even more convincing. If you in any way doubt the message or phone call you receive, do not click on
          anything in the message or hang up, and verify that the message or phone call is legitimate.
        </p>
        <h2>AI Phishing Attacks</h2>
        <p>
          Phishing usually involves tricking people into handing over sensitive information like credit card information
          and passwords using email and other methods. The days when those scam emails stood out like a sore thumb
          because of bad grammar and poor phrasing are almost over! AI has made these scams more effective because it
          can analyze social media profiles to write personalized messages that are very convincing, which increases the
          chances of you falling for the scam. This is why we recommend everyone keep their social media profiles as
          private as possible to lessen the chances of fraudsters having the right information to take advantage.
        </p>
        <ul>
          <li>
            <strong>Do</strong> stop and think before responding or doing anything
          </li>
          <li>
            <strong>Do</strong> contact your bank or other business directly if you get messages saying there is a
            problem with your account; do not click any links and go directly to their website to call the number listed
            on their site
          </li>
          <li>
            <strong>Do</strong> check the sender
          </li>
          <li>
            <strong>Do</strong> hover over links before clicking to verify they're legitimate
          </li>
          <li>
            <strong>Do</strong> ask for help to review any messages or voicemails before responding or clicking on
            anything (maybe from a trusted friend or your bank)
          </li>
          <li>
            <strong>Do</strong> visit or call (using the phone number on their website, never the number in a message)
            the institution you supposedly received a message from
          </li>
        </ul>
        <h2>AI Deepfake Technology: Impersonations</h2>
        <p>
          AI can create hyper-realistic audio clips and videos that can impersonate people, also known as voice-cloning
          and deepfakes. Real videos and photos from social media sites and other sources can help AI generate voice
          clones and deepfakes. Scammers can use that technology to create videos to impersonate company executives or
          people you know to ask for money or sensitive information. In that situation, it can be difficult to tell if
          you're dealing with a real person or a machine with fraudsters behind it. These impersonations also extend to
          company websites, so bookmark their site instead of looking for the site on a search engine each time (like
          your bank's website). If you need to search for it, double-check spelling and call the business directly so
          you can verify the correct URL.
        </p>
        <ul>
          <li>
            <strong>Do</strong> get in contact with a trusted friend, family member, or your bank to verify legitimacy,
            especially if you're asked to keep the call/message a secret
          </li>
          <li>
            <strong>Do</strong> be skeptical of unexpected requests, even if you "know" the requester
          </li>
          <li>
            <strong>Do</strong> call others who are familiar with the requestor to confirm legitimacy before responding
            to the request
          </li>
          <li>
            <strong>Do</strong> be suspicious of an unexpected call, even if the call comes from a recognized phone
            number; caller ID can be faked
          </li>
          <li>
            <strong>Do</strong> look for jerky or unnatural movements if you're sent a video
          </li>
          <li>
            <strong>Do</strong> listen for unnatural-sounding word pronunciation and any inconsistencies in their story
          </li>
          <li>
            <strong>Do</strong> be mindful of anything you share on social media
          </li>
        </ul>
        <h2>AI Analytics and AI Investment Scams</h2>
        <p>
          Legitimate businesses and services can still be shady and try to take advantage of you. Using AI, individual
          fraudsters and legitimate companies can analyze market trends to create convincing but still fraudulent
          investment schemes. These scams might be promoted on social media to make them seem legitimate and may promise
          fast returns on your investment. Remember that if something seems too good to be true, it almost always is.
        </p>
        <ul>
          <li>
            <strong>Do</strong> check to make sure a broker is licensed or if someone complained about them at{" "}
            <a
              href="https://brokercheck.finra.org"
              id="external-link-brokercheck"
              rel="noopener noreferrer"
              target="_blank"
            >
              brokercheck.finra.org
            </a>
          </li>
          <li>
            <strong>Do</strong> ask questions; if you don't understand the investment opportunity, it is not worth
            risking your hard-earned money
          </li>
          <li>
            <strong>Do</strong> your own research and compare any documents you receive from the person or company
            offering the opportunity and verify against records and reports you find yourself
          </li>
          <li>
            <strong>Do</strong> check your{" "}
            <a
              href="https://www.investor.gov/introduction-investing/investing-basics/glossary/state-securities-regulators"
              id="external-link-investor-gov-state-securities-regulators"
              rel="noopener noreferrer"
              target="_blank"
            >
              state securities regulator
            </a>{" "}
            to do additional research and check for pending litigation, theft, or breach of trust
          </li>
          <li>
            <strong>Do</strong> double-check any financial advisor's credentials
          </li>
        </ul>

        <h2>Signs of AI Scams and How to Protect Yourself from AI Scams</h2>
        <p>No system or service is foolproof; you are the best line of defense when it comes to scams.</p>
        <ul>
          <li>
            <strong>Stay informed:</strong> Stay up to date on the latest scams that might impact you, as awareness can
            significantly lower your risk of falling for a scam
          </li>
          <li>
            <strong>Look at the sender:</strong> Take a close look at who sent you a message or email and call or do
            your own research to verify the sender is legitimate
          </li>
          <li>
            <strong>Check links before clicking:</strong> Hover over any links that look suspicious before you click
            anything, and don't open attachments if you aren't expecting the email or message
          </li>
          <li>
            <strong>Trust your gut:</strong> If something feels off, something is wrong. Trust your instincts, and take
            the time to double-check information and make sure things are legitimate
          </li>
        </ul>
        <p>Common signs of any scam, using AI or not, include:</p>
        <ul>
          <li>An emergency situation</li>
          <li>Use of scare tactics</li>
          <li>A sense of urgency where you have to act immediately</li>
          <li>A short conversation, or the caller saying they don't have much time to talk</li>
          <li>A request for money or personal information</li>
          <li>
            Demands for the money to be sent in a way that isn't recoverable, including but not limited to wire
            transfers, gift cards, use of money transfer apps such as Zelle or Venmo, and cryptocurrency
          </li>
          <li>Request to keep the conversation a secret</li>
          <li>Specific instructions to send money or a demand to be on the phone with you while you send money</li>
          <li>An offer that sounds too good to be true</li>
          <li>
            If you receive a video: Jerky or unrealistic movements, changes in skin tone or texture, shadows around the
            eyes, and unusual or lack of blinking
          </li>
          <li>If you receive a phone call: Uncharacteristic word choice or speech compared to the person you know</li>
        </ul>

        <h2>How to Report Scams</h2>
        <p>
          Whether you've been a victim of a scam or you've spotted it before becoming a victim, always report scams to
          the{" "}
          <a
            href="https://reportfraud.ftc.gov"
            id="external-link-reportfraud-ftc"
            rel="noopener noreferrer"
            target="_blank"
          >
            Federal Trade Commission
          </a>
          . Any information you report can help law enforcement bring fraudsters to justice before they get a chance to
          take advantage of someone else.
        </p>

        <h2>WaFd Bank is Here to Help</h2>
        <p>
          No question is too small for our friendly bankers! Not only do we offer *
          <Link to="/personal-banking/free-checking-account" id="internal-page-link-free-checking-account">
            Free Checking
          </Link>{" "}
          and have some great rates on{" "}
          <Link to="/personal-banking/savings-account" id="internal-page-link-savings-account">
            savings accounts
          </Link>{" "}
          and{" "}
          <Link to="/personal-banking/savings-account/certificate-of-deposit" id="internal-page-link-cds">
            CDs
          </Link>
          , but we also like to get to know you personally so we can offer the best assistance possible, including
          helping you determine whether or not you're dealing with a scam. To open an account or to ask us a question,
          give us a call at <a href="tel:800-324-9375">800-324-9375</a> or visit your{" "}
          <Link to="/locations" id="internal-page-link-locations">
            local branch
          </Link>{" "}
          today!
        </p>
        <p className="text-muted text-sm" id="disclaimer">
          *Nonsufficient funds fees may apply.
        </p>
      </>
    </BlogWrapper>
  );
};

export default AIScamsWhatYouShouldKnow;

